<template>
  <div>
    <title>SIM INVENTORY ~ UPDATE DATA LIST OF FINISH GOOD DETAILS OF PRODUCTION PROCESS</title>
    <section class="content-header">
      <h1>
        Update Data List of Finish Good Details of the Production Process
        <br />
        <h4>
         Please enter transaction data for the finish good list from the production process
        </h4>
      </h1>
      <ol class="breadcrumb">
        <li>
          <a href="#"><i class="fa fa-dashboard"></i> Home</a>
        </li>
        <li class="active">
          Finish Good List Transactions from the Production Process
        </li>
      </ol>
    </section>
    <section class="content">
      <div v-if="loading" class="load">
        <img width="130" src="@/assets/loader.gif" alt="" />
      </div>
      
      <div class="row">
        <!-- left column -->
        <div class="col-md-12">
          <!-- general form elements -->
          <div class="box box-primary">
            <div class="box-header with-border">
              <h3 class="box-title">
                Finish Good List Data from the Production Process
              </h3>
            </div>
            <!-- /.box-header -->
            <!-- form start -->
            <form role="form">
              <div class="box-body">
                <div class="form-group">
                  <label for="exampleInputEmail1">Receipt Number </label>
                  <input
                    type="text"
                    v-model="receiptnumb"
                    autocomplete="off"
                    class="form-control"
                    placeholder="Please enter receipt number"
                  />
                </div>
                <div class="form-group">
                  <label for="exampleInputEmail1">Production Number</label>
                  <input
                    type="hidden"
                    readonly
                    v-model="idxnomorproduksi"
                    autocomplete="off"
                    class="form-control"
                  />
                  <input
                    type="text"
                    readonly
                    v-model="productnumb"
                    autocomplete="off"
                    class="form-control"
                  />
                </div>

                <div class="form-group">
                  <label for="exampleInputEmail1">Notes </label>
                  <input
                    type="text"
                    v-model="catatan"
                    autocomplete="off"
                    class="form-control"
                    placeholder="Please enter a note"
                  />
                </div>

                <div class="form-group">
                  <label for="exampleInputEmail1">Qty </label>
                  <input
                    type="number"
                    v-model="stoka"
                    autocomplete="off"
                    class="form-control"
                  />
                  <input
                    type="hidden"
                    readonly
                    v-model="stokb"
                    autocomplete="off"
                    class="form-control"
                  />
                </div>
                <div class="form-group">
                  <label for="exampleInputEmail1">Unit </label>
                  <input
                    type="text"
                    v-model="unitb"
                    autocomplete="off"
                    class="form-control"
                    placeholder="Please enter unit"
                  />
                </div>
              </div>
              <div class="box-footer">
                <button
                  @click="updateData()"
                  type="button"
                  class="btn btn-primary"
                > <i class="fa fa-save"></i>
                  Update
                </button>
                &nbsp;
                <router-link to="/finishgood">
                  <button class="btn btn-success"><i class="fa fa-angle-double-left"></i> Back</button>
                </router-link>
              </div>
            </form>
          </div>
        </div>
      
      </div>
    </section>
  </div>
</template>
<script>
import swal from "sweetalert";
var axios = require("axios");
export default {
  name: "updat",
  data() {
    return {
      loading: false,
      idxnomorproduksi: "",
      productnumb: "",
      receiptnumb: "",
      catatan: "",
      stokb: "",
      stoka: "",
      unitb: ""
    };
  },
  created() {
    this.loadData();
    this.idxpenh = this.$route.params.id;
  },
  methods: {
    loadData() {
      this.loading = true;
      // const tokenlogin = localStorage.getItem("token");
      const tokenlogin = sessionStorage.getItem("token");
      const urlAPIget =
        this.$apiurl +
        "proses_produksi_d_dari_proses_produksi/getstok_hasilk_produksibyid?id=" +
        this.$route.params.id;
      const headers = {
        Accept: "application/json",
        Authorization: tokenlogin
      };
      axios
        .get(urlAPIget, { headers })
        .then((resp) => {
          console.log(resp);
          this.idxnomorproduksi = resp.data.data.id;
          this.productnumb = resp.data.data.nomor_produksi;
          this.stoka = resp.data.data.stok;
          this.stokb = resp.data.data.stok;
          this.loading = false;
        })
        .catch((err) => {
          console.log(err);
        });
    },

    async updateData() {
      this.loading = true;
      var idxnomprod = this.idxnomorproduksi;
      var prodnumb = this.productnumb;
      var catat = this.catatan;
      if (prodnumb == "") {
        swal({
          icon: "warning",
          title: "Warning",
          text: "Production Number can't be empty",
          showConfirmButton: false
        });
        this.loading = false;
      } else if (catat == "") {
        swal({
          icon: "warning",
          title: "Warning",
          text: "Notes can't be empty",
          showConfirmButton: false
        });
        this.loading = false;
      } else {
        if (this.stoka > this.stokb) {
          swal({
            icon: "warning",
            title: "Peringatan",
            text: "Sorry, the amount you entered exceeds the existing stock",
            showConfirmButton: false
          });
          this.loading = false;
        } else {
          const kodeuser = sessionStorage.getItem("kodeuser");
          const paramdatadetail = {
            receipt_number: this.receiptnumb,
            id_nomor_proses_produksi: this.idxnomorproduksi,
            notes: this.catatan,
            qty: this.stoka,
            kode_user: kodeuser,
            unit: this.unitb
          };

          console.log(paramdatadetail);
          // const tokenlogin = localStorage.getItem("token");
          const tokenlogin = sessionStorage.getItem("token");
          const headers = {
            Accept: "application/json",
            Authorization: tokenlogin
          };

          const urlAPIUpdateData =
            this.$apiurl +
            "penerimaan_fg_d/updatepenerimaan_fg_d_internal/" +
            idxnomprod;
          axios
            .put(urlAPIUpdateData, paramdatadetail, { headers: headers })
            .then((respn) => {
              console.log(respn);
              if (respn.data.status == true) {
                swal({
                  icon: "success",
                  title: "Success",
                  text: "Update data successfully",
                  showConfirmButton: false
                });
                this.loading = false;
                this.$router.push({ name: "finishgoodheader" });
              } else {
                swal({
                  icon: "warning",
                  title: "Warning",
                  text: "Update data failed",
                  showConfirmButton: false
                });
                this.loading = false;
                this.$router.push({ name: "finishgoodheader" });
              }
            })
            .catch((err) => {
              swal({
                icon: "warning",
                title: "Warning",
                text: err.response.data,
                showConfirmButton: false
              });
              console.log(err);
              this.loading = false;
            });
        }
      }
    }
  }
};
</script>
<style>
.load {
  width: 20%;
  margin: 0 auto;
}
</style>
